<script>
import { ref, watch } from "vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import "flatpickr/dist/flatpickr.css";

import useVuelidate from "@vuelidate/core";

import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";
import PageHeader from "@/components/page-header";
import Axios from "@/config/axios";
import store from "../../state/store";

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export default {
  page: {
    title: "Modifier une formation",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    let files = ref([]);
    let dropzoneFile = ref("");
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
      files.value.push(dropzoneFile.value);
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
      files.value.push(dropzoneFile.value);
    };
    watch(
      () => [...files.value],
      (currentValue) => {
        return currentValue;
      }
    );
    return {
      dropzoneFile,
      drop,
      selectedFile,
      v$: useVuelidate(),
      files,
    };
  },
  data() {
    return {
      title: "Modifier une formation",
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Modifier une formation",
          active: true,
        },
      ],
      categories: [],
      date1: null,
      datetimeConfig: {
        enableTime: true,
        dateFormat: "d.m.y",
        time_24hr: true,
      },
      loading: false,
      current_product: {},
      fields: {
        specifications: [{ property: "", value: "" }],
        categories: [],
        images: [],
        name: "",
        isNew: false,
        isHot: false,
        isOutOfStock: false,
        price: 0,
        sale_price: 0,
        stock: 0,
        short_description: "",
        is_published: [true],
        author: "",
        nb_students: 0,
      },
    };
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  async beforeRouteEnter({ params }) {
    let current_product = {};
    let categories = [];

    await Axios.get("/admin/courses/" + params.id).then(({ data }) => {
      current_product = data.data;
    });

    await Axios.get("/admin/course-categories")
      .then(({ data }) => {
        categories = data.data;
      })
      .catch((er) => {
        console.log(er);
      });

    localStorage.setItem("@CURRENT_COURSE", JSON.stringify(current_product));
    localStorage.setItem("@COURSES_CATEGORIES", JSON.stringify(categories));
  },

  beforeMount() {
    this.categories = JSON.parse(
      localStorage.getItem("@COURSES_CATEGORIES") || "[]"
    );
    this.current_product = JSON.parse(
      localStorage.getItem("@CURRENT_COURSE") || "[]"
    );
    this.fields = {
      author: this.current_product.author,
      nb_students: this.current_product.nb_students,
      name: this.current_product.name,
      isNew: this.current_product.is_new == 1,
      isHot: this.current_product.is_hot == 1,
      isOutOfStock: this.current_product.is_out_of_stock == 1,
      price: this.current_product.price,
      sale_price: this.current_product.sale_price,
      stock: this.current_product.stock,
      short_description: this.current_product.short_description,
      is_published: [this.current_product.is_published == 1],

      specifications: this.current_product.details.map((item) => ({
        property: item.key,
        value: item.value,
      })),
      categories: this.current_product.product_categories.map(
        (category) => category.id
      ),
      images: this.current_product.large_pictures.map(
        (picture) => picture.large
      ),
    };
  },
  methods: {
    check_validation() {
      if (!this.fields.name.trim()) {
        alert("Veuillez renseigner le nom de la formation");
        return false;
      }

      if (!this.fields.categories.length) {
        alert("Veuillez renseigner au moins une catégorie à la formation");
        return false;
      }

      if (!this.fields.images.length) {
        alert("Veuillez renseigner au moins une image à la formation");
        return false;
      }

      return true;
    },
    async handleSubmit() {
      store.dispatch("setGlobalLoading", true);

      if (!this.check_validation()) {
        store.dispatch("setGlobalLoading", false);
        return false;
      }

      let i = 0;
      for (const image of this.fields.images) {
        if (!image.startsWith("http")) {
          const url = await this.$upload_file(image, this.fields.name);
          this.fields.images[i] = url;
        }
        i++;
      }

      await Axios.put("/admin/courses/" + this.$route.params.id, this.fields)
        .then(() => {
          alert("La formation a bien été mise à jour !");
          store.dispatch("setGlobalLoading", false);
          this.$router.push("/courses");
        })
        .catch((er) => {
          console.log(er);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleAddSpec() {
      this.fields.specifications = [
        ...this.fields.specifications,
        { property: "", value: "" },
      ];
    },
    handleRemoveSpec(index) {
      this.fields.specifications = this.fields.specifications.filter(
        (item, id) => id != index
      );
    },
    handleRemove(image) {
      this.fields.images.splice(this.fields.images.indexOf(image), 1);
    },

    async uploadImages(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const base64 = await convertBase64(file);
        this.fields.images = [...this.fields.images, base64];
      }
    },

    async handleDrop(event) {
      for (const item of [...event.dataTransfer.items]) {
        const file = item.getAsFile();
        const base64 = await convertBase64(file);
        this.fields.images = [...this.fields.images, base64];
      }
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-8">
        <form @submit.prevent="handleSubmit">
          <div class="card">
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label" for="product-title-input"
                  >Titre de la formation</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="product-title-input"
                  placeholder="Entrez le titre de la formation"
                  v-model="fields.name"
                />
              </div>

              <div class="form-group mb-3">
                <label class="form-label" for="product-title-input"
                  >Catégorie de la formations</label
                >

                <Multiselect
                  class="form-control"
                  mode="tags"
                  v-model="fields.categories"
                  :close-on-select="false"
                  :searchable="true"
                  :create-option="true"
                  :options="[
                    ...categories.map((category) => ({
                      value: category.id,
                      label: category.name,
                    })),
                  ]"
                />
              </div>

              <!-- <div class="row mb-3 align-items-center">
                <div class="col-6">
                  <label class="form-label" for="product-title-input">
                    Quantité en stock
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="product-title-input"
                    placeholder="Stock"
                    v-model="fields.stock"
                  />
                </div>

                <div class="col-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="is_out_of_stock"
                      v-model="fields.isOutOfStock"
                    />
                    <label class="form-check-label" for="is_out_of_stock">
                      En rupture de stock ?
                    </label>
                  </div>
                </div>
              </div> -->

              <div class="row mb-3">
                <div class="col-6">
                  <label class="form-label" for="product-title-input">
                    Auteur de la formation
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="product-title-input"
                    placeholder="Entrez le prix de la formation"
                    v-model="fields.author"
                  />
                </div>

                <div class="col-6">
                  <label class="form-label" for="product-title-input">
                    Nombre d'inscrits
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="product-title-input"
                    placeholder="Entrez le prix promo"
                    v-model="fields.nb_students"
                  />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-6">
                  <label class="form-label" for="product-title-input">
                    Prix de la formation
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="product-title-input"
                    placeholder="Entrez le prix de la formation"
                    v-model="fields.price"
                  />
                </div>

                <div class="col-6">
                  <label class="form-label" for="product-title-input">
                    Prix solde
                    <small
                      >(Laisser vide si la formation n'est pas en solde)</small
                    >
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="product-title-input"
                    placeholder="Entrez le prix promo"
                    v-model="fields.sale_price"
                  />
                </div>
              </div>

              <!-- <div class="row mb-3">
                <div class="col-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="is_new"
                      v-model="fields.isNew"
                    />
                    <label class="form-check-label" for="is_new">
                      Nouveauté
                    </label>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="is_hot"
                      v-model="fields.isHot"
                    />
                    <label class="form-check-label" for="is_hot">
                      Etiquette (CHAUD)
                    </label>
                  </div>
                </div>
              </div> -->

              <div>
                <label>Description de la formation</label>
                <textarea
                  class="form-control"
                  name="description"
                  id="description"
                  rows="10"
                  v-model="fields.short_description"
                ></textarea>
              </div>
            </div>
          </div>
          <!-- end card -->

          <div
            class="card"
            @drop.prevent="handleDrop"
            @dragenter.prevent
            @dragover.prevent
          >
            <div class="card-header">
              <h5 class="card-title mb-0">Galerie de la formation</h5>
            </div>
            <div class="card-body">
              <div class="vstack gap-2">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="fs-14 mb-1">Galerie de la formations</h5>
                    <p class="text-muted">
                      Ajouter des images de la galerie de la formation.
                    </p>
                  </div>

                  <div class="col-6 d-flex justify-content-end">
                    <label class="btn btn-primary" for="img-file-input">
                      + Ajouter des images
                    </label>
                    <input
                      id="img-file-input"
                      type="file"
                      class="img-file-input d-none"
                      multiple
                      @change="uploadImages"
                    />
                  </div>

                  <label
                    class="drop-zone"
                    for="img-file-input"
                    @drop.prevent="handleDrop"
                    @dragenter.prevent
                    @dragover.prevent
                  >
                    <div>
                      Cliquez pour ajouter ou des images ou glissez-déposez
                    </div>
                  </label>
                </div>
                <!-- <DropZone @drop.prevent="drop" @change="selectedFile" /> -->

                <div class="row">
                  <div
                    class="col-4 col-md-4"
                    style="position: relative"
                    v-for="image in fields.images"
                    :key="image"
                  >
                    <img
                      style="max-width: 95%"
                      class="w-100"
                      :src="image"
                      alt=""
                    />
                    <div
                      style="
                        position: absolute;
                        right: 5px;
                        top: -10px;
                        background: gray;
                        height: 20px;
                        width: 20px;
                        text-align: center;
                        vertical-align: middle;
                        border-radius: 50%;
                        color: white;
                        cursor: pointer;
                      "
                      @click="handleRemove(image)"
                    >
                      X
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card -->

          <div class="card">
            <div class="card-header">
              <ul
                class="nav nav-tabs-custom card-header-tabs border-bottom-0"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    data-bs-toggle="tab"
                    href="#addproduct-general-info"
                    role="tab"
                  >
                    Spécifications de la formation
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#addproduct-metadata" role="tab">
                    Meta Data
                  </a>
                </li> -->
              </ul>
            </div>
            <!-- end card header -->
            <div class="card-body">
              <div class="tab-content">
                <div
                  class="tab-pane active"
                  id="addproduct-general-info"
                  role="tabpanel"
                >
                  <div
                    class="row align-items-center mb-2"
                    v-for="(item, index) in fields.specifications"
                    :key="index"
                  >
                    <div class="col-lg-5">
                      <div class="mb-3">
                        <label class="form-label">Proprieté</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Entrez la proprieté"
                          v-model="item.property"
                        />
                      </div>
                    </div>
                    <!-- end col -->
                    <div class="col-lg-5">
                      <div class="mb-3">
                        <label class="form-label" for="product-price-input"
                          >Valeur</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="manufacturer-brand-input"
                          placeholder="Entrez la valeur"
                          v-model="item.value"
                        />
                      </div>
                    </div>
                    <div class="col-lg-2">
                      <button
                        @click="handleRemoveSpec(index)"
                        type="button"
                        class="btn btn-danger"
                      >
                        Effacer
                      </button>
                    </div>
                    <!-- end col -->
                  </div>
                  <!-- end row -->

                  <button
                    type="button"
                    class="btn btn-secondary mt-2"
                    style="float: right"
                    @click="handleAddSpec"
                  >
                    Ajouter
                  </button>
                </div>
                <!-- end tab-pane -->

                <div class="tab-pane" id="addproduct-metadata" role="tabpanel">
                  <div class="row">
                    <!-- <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label" for="meta-title-input">Meta title</label>
                        <input type="text" class="form-control" placeholder="Enter meta title" id="meta-title-input" />
                      </div>
                    </div> -->
                    <!-- end col -->

                    <!-- <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label" for="meta-keywords-input">Meta Keywords</label>
                        <input type="text" class="form-control" placeholder="Enter meta keywords"
                          id="meta-keywords-input" />
                      </div>
                    </div> -->
                    <!-- end col -->
                  </div>
                  <!-- end row -->

                  <!-- <div>
                    <label class="form-label" for="meta-description-input">Meta Description</label>
                    <textarea class="form-control" id="meta-description-input" placeholder="Enter meta description"
                      rows="3"></textarea>
                  </div> -->
                </div>
                <!-- end tab pane -->
              </div>
              <!-- end tab content -->
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
          <div class="text-end mb-3">
            <button type="submit" class="btn btn-success w-sm">
              Enregistrer la formation
            </button>
          </div>
        </form>
      </div>
      <!-- end col -->

      <div class="col-lg-4">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Publier</h5>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label for="choices-publish-status-input" class="form-label"
                >Statut</label
              >
              <Multiselect
                class="form-control"
                v-model="fields.is_published"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                :options="[
                  { value: true, label: 'Publié' },
                  { value: false, label: 'Brouillon' },
                ]"
              />
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.drop-zone {
  width: 96%;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.2);
}
.drop-zone:hover {
  border: 1px solid rgba(0, 0, 0, 0.6);
  color: rgba(0, 0, 0, 0.6);
}
</style>
